import React, { useRef } from 'react';
import { Fade } from 'react-reveal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules';


const Banner = ({ banner }) => {
  // Reference to the Swiper instance
  const swiperRef = useRef(null);

  return (
    <Fade bottom>
      <section className='banner-section container-fluid'>
        <div className='container banner'>
          <Swiper
            ref={swiperRef}
            spaceBetween={10} // Adjust this value as needed
            modules={[Pagination]}
            autoplay={true}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                // Use the custom bullet class
                return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
              },
            }}
          >
            {banner.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image_url} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </Fade>
  );
}

export default Banner;

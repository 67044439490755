


import { combineReducers, configureStore } from "@reduxjs/toolkit";
import homeReducer from '../slices/homeSlice';
import aboutReducer from '../slices/aboutSlice';
import productReducer from '../slices/productsSlice';
import userReducer from '../slices/userSlice';
import sharedReducer from '../slices/sharedSlice'

const reducer = combineReducers({
    homeState : homeReducer,
    aboutState : aboutReducer,
    productState: productReducer,
    userState: userReducer,
    sharedState: sharedReducer,
})

// Configure store using Redux Toolkit
const store = configureStore({
    reducer,
})

export default store;


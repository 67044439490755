import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loading: false,
    profile: {},

}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        profileSuccces(state, action) {
            return {
                ...state,
                profile: action.payload
            }
        },
        onChangeProfile(state, action) {
            return {
                ...state,
                profile: action.payload
            }
        }
    },
})

const { actions, reducer } = userSlice;

export const { profileSuccces, onChangeProfile } = actions;

export default reducer;
import React from 'react'
import '../styles/applicationAreas.scss'
import { Fade } from 'react-reveal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setFilter } from '../slices/productsSlice'
import { useDispatch } from 'react-redux'


const ApplicationAreas = ({ content }) => {
  const dispatch = useDispatch()
  const { applicationAreas } = useSelector(state => state.sharedState);
  const navigate = useNavigate();

  const limitedApplicationAreas = applicationAreas.slice(0, 16);
  const handleAreas = (area) => {
    dispatch(setFilter({ areas: area.id, areasName: area.name }))
    navigate(`/products?areas=${area.id}`)
  }

  return (
    <section className='application-area-section container-fluid'>
      <div className='container application-area'>
        <Fade bottom>
          <div className='top-section'>
            <h1>{content?.title ? content.title : ''}</h1>
            <div className='top-section-content'>
              <p>{content?.description ? content.description : ''}</p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className='bottom-section'>
            {limitedApplicationAreas.map((area, index) => (
              <div className='custom-box' key={index} onClick={() => handleAreas(area)}>
                <caption className='text'>{area.area_name}</caption>
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default ApplicationAreas;

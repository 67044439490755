import React, { useState, useEffect } from 'react'
import { logo } from '../assets';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import LoginRegister from '../authorisation/container/LoginRegister';
import { checkUserLogin, clearAllCookies, } from '../utils';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import TabHeader from './TabHeader';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ShopMegaMenu from './ShopMegaMenu';
import MegaMenu from './MegaMenu';
import { contactBasicApi, getAboutUsMegaMenuApi, getShopMenuLeftApi, getShopMenuRightApi } from './apiServices';
import { useLocation } from 'react-router-dom';


const Header = (props) => {
  const location = useLocation()
  const { profile } = useSelector(state => state.userState)
  const { carts } = useSelector(state => state.productState)
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);
  const [isShopMegaMenuShown, setIsShopMegaMenuShown] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (isShown) {
      setIsShopMegaMenuShown(false)
    }
  }, [isShown])

  useEffect(() => {
    if (isShopMegaMenuShown) {
      setIsShown(false)
    }
  }, [isShopMegaMenuShown])

  const { contactHeader, socialMediaLinks } = useSelector((state) => state.sharedState)

  useEffect(() => {
    dispatch(getAboutUsMegaMenuApi());
    dispatch(contactBasicApi());
    dispatch(getShopMenuLeftApi());
    dispatch(getShopMenuRightApi())
  }, [dispatch]);


  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  const headerStyle = {
    backgroundColor: scrollPosition > 50 ? 'rgba(	57, 61, 70, 0.5)' : 'transparent',
    zIndex: 1000,
  };
  const logout = () => {
    clearAllCookies()
    window.location.reload();
  }


  const handleOrder = () => {
    const message = contactHeader && contactHeader.wa_message ? contactHeader.wa_message : '';
    const phoneNumber = contactHeader && contactHeader.wa_number ? contactHeader.wa_number : '';

    // Open WhatsApp with a pre-filled message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  console.log('lov', location.pathname)
  return (
    <>
      {isTablet ? (
        <TabHeader />
      ) : (
        <header style={headerStyle} className='header mask-custom'
          onMouseLeave={() => {
            setIsShown(false);
            setIsShopMegaMenuShown(false);
          }}
        >
          {isShown && <MegaMenu onMouseLeave={() => setIsShown(false)} />}
          {isShopMegaMenuShown && <ShopMegaMenu onMouseLeave={() => setIsShopMegaMenuShown(false)} />}
          <div className='container-fluid top-header ' >
            <Container className="top-navigation-bar" maxWidth="sm">

              <div className="phone-email">
                <div className="flex gap-1 phone">
                  <i className="fas fa-phone-alt" style={{ color: '#e2e2e2' }}></i>
                  <a style={{ color: '#e2e2e2', textDecoration: 'none' }} href={`tel:${contactHeader && contactHeader.phone1 ? contactHeader.phone1 : ''}`}>
                    {contactHeader && contactHeader.phone1 ? contactHeader.phone1 : ''}
                  </a>
                  <a style={{ color: '#e2e2e2', textDecoration: 'none' }} href={`tel:${contactHeader && contactHeader.phone2 ? contactHeader.phone2 : ''}`}>
                    {contactHeader && contactHeader.phone2 ? contactHeader.phone2 : ''}
                  </a>
                </div>
                <div className='flex gap-1 email'>
                  <i className="fas fa-envelope" style={{ color: '#e2e2e2' }}></i>
                  <a style={{ color: '#e2e2e2', textDecoration: 'none' }} href={`mailto:${contactHeader && contactHeader.mail ? contactHeader.mail : ''}`}>
                    {contactHeader && contactHeader.mail ? contactHeader.mail : ''}
                  </a>
                </div>
              </div>

              <div class="social-icons" >
                {checkUserLogin() ? (
                  <a href="/cart" style={{ color: '#fff' }} className='cart' rel="noreferrer">
                    <i class="fas fa-shopping-cart"></i> Your Cart ({carts?.length})
                  </a>
                ) : null}


                {socialMediaLinks.length > 0 ? (
                  <>
                    {socialMediaLinks.map((socialItem, index) => (
                      <div key={index}>
                        <a href={socialItem.link} target='_blank' rel='noreferrer'>
                          <img src={socialItem.icon} alt={socialItem.platform} style={{ height: '30px', width: '30px' }} />
                        </a>
                      </div>
                    ))}

                    {/* WhatsApp icon outside the loop */}
                    <div>
                      <a
                        href="https://www.wa.me/"
                        onClick={() => handleOrder()}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" style={{
                          color: '#E2E2E2', fontSize: '15px'
                        }}></i>
                      </a>
                    </div>
                  </>
                ) : (
                  null
                )}
              </div>

            </Container>
          </div>
          <div className='container-fluid bottom-header'>
            <div className="bottom-navigation-bar container">
              <div className='logo'>
                <Link to="/"><img src={logo} alt="logo" /></Link>
              </div>
              <div className="navigation">
                <ul>
                  <li >
                    <Link style={{ color: location.pathname === "/" ? '#FCAF17' : '#fff', textDecoration: 'none' }}
                      to="/"
                      onMouseEnter={props.mouseEnterNav}>Home</Link>
                  </li>
                  <li>
                    <Link style={{ color: location.pathname.includes('/about-us') ? '#FCAF17' : '#fff', textDecoration: 'none' }}
                      to="/about-us?messages"
                      onMouseEnter={() => {
                        setIsShown(true);
                        // setIsShopMegaMenuShown(true);
                      }}>
                      About us
                    </Link></li>


                  <li>
                    <Link style={{ color: location.pathname === "/products" ? '#FCAF17' : '#fff', textDecoration: 'none' }}
                      to="/products"
                      onMouseEnter={() => {
                        setIsShopMegaMenuShown(true);
                      }}
                    >
                      Shop
                    </Link>
                  </li>
                  <li><Link style={{ color: location.pathname === "#" ? '#FCAF17' : '#fff', textDecoration: 'none' }}
                    to="/blog"
                    onMouseEnter={props.mouseEnterNav}>Blog</Link></li>
                </ul>
              </div>
              <div className='header-cta' >
                <button type='button' className='btn btn-light btn-pad'>Smart Touch</button>
                {
                  checkUserLogin() ?
                    // <div className="header-user">
                    //   <i class="fas fa-user-circle"></i>
                    //   <p>Chetan</p>
                    //   <i className='fa fa-angle-down'></i>
                    // </div>
                    <DropdownButton
                      as={ButtonGroup}
                      key={''}
                      // id={`dropdown-variants-${''}`}
                      variant={'secondary'}
                      title={
                        profile?.image ?
                          <span><img style={{ width: '30px' }} src={profile?.image} alt='' />&nbsp;&nbsp; {profile?.first_name || ''}</span>

                          :
                          <span> <i class="fas fa-user-circle"></i> &nbsp;&nbsp; {profile?.first_name || ''}</span>
                      }
                    // style={{ width: '100%' }}
                    >
                      <Dropdown.Item eventKey="2" onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
                      <Dropdown.Item eventKey="1" onClick={() => logout()}>Logout</Dropdown.Item>
                    </DropdownButton>


                    : <LoginRegister />
                }

              </div>
            </div>
          </div>
        </header >
      )}

    </>
  )
}

export default Header
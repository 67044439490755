import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popupMessage:'',
  thankYou:'',
  loading: false,
  brands: [],
  categories: [],
  applicationAreas: [],
  shopMegaMenu: {},
  aboutUsMegaMenu: [],
  allSubCategory: [],
  socialMediaLinks:[],
  contactHeader:{},
  footerAbouts:{},
  sliderAbouts:[],
  quotationData:[],
  contactShowroom: [],
  contactBranches:[],
  contactHeadquarters:{},
  authModal: false,
  cartLogin: false,
  showPopup: false,
  seo:{}
};
const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    loadingOn(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    loadingOff(state, action) {
      return {
        ...state,
        loading: false,
      };
    },
    brandsSuccess(state, action) {
      return {
        ...state,
        brands: action.payload,
      };
    },
    applicationSuccess(state, action) {
      return {
        ...state,
        applicationAreas: action.payload,
      };
    },
    categoriesSuccess(state, action) {
      return {
        ...state,
        categories: action.payload,
      };
    },

//about us megamenu
    aboutUsMegaMenuSuccess(state, action) {
      return {
        ...state,
        aboutUsMegaMenu: action.payload,
      };
    },


    shopMegaMenuLeftSuccess(state, action) {
      return {
        ...state,
        shopMegaMenu: {
          ...state.shopMegaMenu,
          left: action.payload,
        },
      };
    },
    shopMenuRightSuccess(state, action) {
      return {
        ...state,
        shopMegaMenu: {
          ...state.shopMegaMenu,
          right: action.payload,
        },
      };
    },
    authModalToggle(state, action) {
      return {
        ...state,
        authModal: !state.authModal,
      };
    },
    cartLoginToggle(state, action) {
      return {
        ...state,
        cartLogin: !state.cartLogin,
      };
    },

    footerSuccess(state, action) {
      return {
        ...state,
        allSubCategory:action.payload,
      };
    },
    
    socailMediaLinksSucess(state,action){
      return{
        ...state,
        socialMediaLinks:action.payload,
      };
    },
    
    contactBasicSucess(state,action){
      return{
        ...state,
        contactHeader:action.payload,
      };
    },

    contactShowroomSucess(state,action){
      return{
        ...state,
        contactShowroom:action.payload,
      };
    },

    contactHeadquartersSucess(state,action){
      return{
        ...state,
        contactHeadquarters:action.payload,
      };
    },
    

    
    contactBranchesSucess(state,action){
      return{
        ...state,
        contactBranches:action.payload,
      };
    },


    footerAboutSucess(state,action){
      return{
        ...state,
        footerAbouts:action.payload,
      };
    },

    sliderSucess(state,action){
      return{
        ...state,
        sliderAbouts:action.payload,
        
      };
    },


    quotationSucess(state,action){
      return{
        ...state,
        quotationData:action.payload,

      };
    },
    seoSuccess(state,action){
      return {
        ...state,
        seo:action.payload
      }
    },
    setPopupMessage(state, action){
      return {
        ...state,
        popupMessage: action.payload
      }
    },
    setShowPopUp(state, action){
      return{
        ...state,
        showPopup: action.payload

      }
    },
    setThankYou(state, action){
      return {
        ...state,
        thankYou: action.payload
      }
    }
  },


});

const { actions, reducer } = sharedSlice;

export const {
  contactShowroomSucess,
  contactHeadquartersSucess,
  footerSuccess,
  loadingOn,
  loadingOff,
  brandsSuccess,
  applicationSuccess,
  categoriesSuccess,
  aboutUsMegaMenuSuccess,
  shopMegaMenuSuccess,
  shopMegaMenuLeftSuccess,
  shopMenuRightSuccess,
  authModalToggle,
  cartLoginToggle,
  socailMediaLinksSucess,
  contactBasicSucess,
  footerAboutSucess,
  sliderSucess,
  quotationSucess,
  contactBranchesSucess,
  seoSuccess,
  setPopupMessage,
  setShowPopUp,
  setThankYou

} = actions;


export default reducer;
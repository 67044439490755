import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    heroSlides: [],
    testimonials: [],
    banner: [],
    home: {},
    blog:[]
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        heroSlidesSucces(state, action) {
            return {
                ...state,
                heroSlides: action.payload
            }
        },
        testimonialsSucess(state, action) {
            return {
                ...state,
                testimonials: action.payload
            }
        },
        bannerSucess(state, action) {
            return {
                ...state,
                banner: action.payload
            }
        },
        homeSuccess(state, action) {
            return {
                ...state,
                home: action.payload
            }
        },
        blogSuccess(state,action){
            return {
                ...state,
                blog:action.payload
            }
        }
    }
});

const { actions, reducer } = homeSlice;

export const {blogSuccess, heroSlidesSucces, testimonialsSucess, bannerSucess, homeSuccess } = actions;

export default reducer;
import React, { useEffect, useState } from 'react'
import FullLogo  from '../assets/Tab/FullLogo.png';
import toggle  from '../assets/Tab/toggle.png';
import '../styles/tabHeader.scss'
import { Link } from 'react-router-dom';
import LoginRegister from '../authorisation/container/LoginRegister';
import { useNavigate } from 'react-router-dom';
import { checkUserLogin, clearAllCookies,} from '../utils';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function TabHeader(props) {
    const [openNav, setOpenNav] = useState(false)
    const { profile } = useSelector(state => state.userState)
    const navigate = useNavigate();
    const toggleButton = () => {
        setOpenNav(!openNav);
      };
      const logout = () => {
        clearAllCookies()
        window.location.reload();
      }      
      useEffect(() => {
        if (openNav) {
          document.body.style.overflow = "hidden"
        } else {
          document.body.style.overflow = "auto"
        }
      }, [openNav])
  return (
    <header className='container-fluid'>
        <div className="container">
            <div className="tab-header-section">
                <div className="tab-logo-section">
                <Link to="/"><img src={FullLogo} alt="logo" /></Link>
                </div>
                <div className="tab-button-section">
                <div className="touch-button">
                    <button>Smart Touch</button>
                </div>
                
                <div className='header-cta' >
                {
                  checkUserLogin() ?
                    <DropdownButton
                      as={ButtonGroup}
                      key={''}
                      // id={`dropdown-variants-${''}`}
                      variant={'secondary'}
                      title={
                        profile?.image ?
                          <span><img style={{ width: '30px' }} src={profile?.image} alt='' />&nbsp;&nbsp; {profile?.first_name || ''}</span>

                          :
                          <span> <i class="fas fa-user-circle"></i> &nbsp;&nbsp; {profile?.first_name || ''}</span>
                      }
                      // style={{ width: '100%' }}
                    >
                      <Dropdown.Item eventKey="2" onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
                      <Dropdown.Item eventKey="1" onClick={() => logout()}>Logout</Dropdown.Item>
                    </DropdownButton>

                    : <LoginRegister />
                }

              </div>
              <div className="toggle-button">
                    <img onClick={toggleButton} src={toggle} alt=""  />
                </div>
            </div>
        </div>
           

        </div>
        {
        openNav &&
        <div className='nav_fixed'>
          <div className='overlay1'>
            <div onClick={toggleButton} className='close_icon'>
              <i className="fa fa-close"></i>
            </div>
            <div className='modal-contentD  '>
              <ul>
                <Link className='nav-content' onClick={toggleButton} to="/"><li className='nav-head'>Home</li></Link>
                <Link className='nav-content' onClick={toggleButton} to="/about-us?messages"><li className='nav-head'>About Us</li></Link>
                <Link className='nav-content' onClick={toggleButton} to="/products"><li className='nav-head'>Shop</li></Link>
                <Link className='nav-content' onClick={toggleButton} to="/cart"><li className='nav-head'>ProductCart</li></Link>
                <Link className='nav-content' onClick={toggleButton} to="/cart"><li className='nav-head'>Blog</li></Link>
              </ul>
            </div>
          </div>
        </div>
        }
        
    </header>
  )
}

export default TabHeader
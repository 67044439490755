import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MobileApplicationAreas = ({ content }) => {
  const { applicationAreas } = useSelector((state) => state.sharedState);
  const navigate = useNavigate();

  const limitedApplicationAreas = applicationAreas.slice(0, 16);

  return (
    <section className='mobile-application-areas-section container-fluid'>
      <div className='application-areas container'>
        <div className='top-section'>
          <h4>{content?.title ? content.title : ''}</h4>
        </div>
        <div className='bottom-section row'>
          {limitedApplicationAreas.map((area, index) => (
            <div className='custom-box col-md-3' key={index} onClick={() => navigate(`/products?areas=${area.id}`)}>
              <p>{area.area_name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MobileApplicationAreas;

import { globalPostService } from "../utils/globalApiServices";

export const registerApi = (payload) => {
  return new Promise((resolve, reject) => {
    globalPostService("api/register", payload)
    .then(response => {
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    });
      
  });
}

export const loginApi = (payload) => {
  return new Promise((resolve, reject) => {
    globalPostService("api/login", payload)
    .then(response => {
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    });
      
  });
}
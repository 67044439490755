import React, { useEffect } from 'react'
import { Quotation } from '../home/components'
import '../styles/tabFooter.scss'
import FooterQuotation from './FooterQuotation'
import twitterlogo from "../assets/icons/icons8-twitter.svg"
import { footerAboutApi, footerContentApi, socailMediaLinksApi } from './apiServices'
import { useDispatch, useSelector } from 'react-redux'
import { getBrandsApi } from '../home/apiServices.js'

import { useNavigate } from 'react-router-dom'
import { setFilter } from '../slices/productsSlice.js'
import { contactShowroomApi, contactBranchesApi, contactHeadQuartersApi } from '../components/apiServices'



function TabFooter() {
  const { contactShowroom, contactBranches, contactHeadquarters, contactHeader } = useSelector((state) => state.sharedState);
  const { allSubCategory, brands, socialMediaLinks, footerAbouts } = useSelector((state) => state.sharedState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(footerContentApi());
    dispatch(getBrandsApi());
    dispatch(socailMediaLinksApi());
    dispatch(footerAboutApi());
    dispatch(contactHeadQuartersApi());
    dispatch(contactBranchesApi());
  }, []);


  const handleOrder = () => {
    const message = contactHeader && contactHeader.wa_message ? contactHeader.wa_message : '';
    const phoneNumber = contactHeader && contactHeader.wa_number ? contactHeader.wa_number : '';
    let url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleCategories = (category) => {
    dispatch(setFilter({ category: category.id, categoryName: category.name }))
    navigate(`/products`)
  }

  const handleSubCategory = (child, item) => {
    dispatch(setFilter({ subcategory: child.id, subcategoryName: child.description, category: item.id, categoryName: item.name }));
    navigate(`/products`);
  }
  return (
    <div className="container-fluid tab-footer">
      <div className="container">
        <div>
          <FooterQuotation />
        </div>
        <div className="tab-footer-section">
          <div className="footer-part-one">
            <div className="footer-about-section ">
              <div className="about-head">
                {footerAbouts.length > 0 ? (
                  footerAbouts.map((footerItem, index) => (
                    <div key={index}>
                      <h3 className='title'>{footerItem && footerItem.subtitle}</h3>
                      <p className='name'>{footerItem && footerItem.title}</p>
                      <p className='content'>{footerItem && footerItem.content}</p>
                    </div>
                  ))
                ) : (
                  null
                )}
              </div>
              <div className="footer-about-content">
                <p className='content'>{footerAbouts.content}</p>
              </div>
              <div className="footer-icons">
                {socialMediaLinks.length > 0 ? (
                  <>
                    {socialMediaLinks.map((socialItem, index) => (
                      <div key={index}>
                        <a href={socialItem.link} target='_blank' rel='noreferrer'>
                          <img src={socialItem.icon} alt={socialItem.platform} style={{ height: '30px', width: '30px' }} />
                        </a>
                      </div>
                    ))}

                    {/* WhatsApp icon outside the loop */}
                    <div>
                      <a
                        href="https://www.wa.me/"
                        onClick={() => handleOrder()}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" style={{
                          color: '#E2E2E2', fontSize: '20px'
                        }}></i>
                      </a>
                    </div>
                  </>
                ) : (
                  null
                )}
              </div>
            </div>
            <div className="branch">
              <div className="head-office">
                <h3>Head Office</h3>
                <p>{contactHeadquarters?.address}</p>
                <a style={{ color: '#e2e2e2', textDecoration: 'none' }} href={`tel:${contactHeadquarters.phone_1} `}><p>{contactHeadquarters.phone_1}</p></a>
              </div>
              <div className="reach-us">
                <h3>Reach US</h3>
               <a  style={{ textDecoration: 'none' ,color: '#e2e2e2'}} href={`mailto:${contactHeadquarters.email}`}><p>{contactHeadquarters.email}</p></a>

              </div>
            </div>
             <div className='branch'> 
              {contactBranches.map((branch) => (

              <div className='head-office'>
                <p style={{ color: '#393D46' }}className='title'> {branch.branch_name}</p>
                <p className='content'>{branch.address}


                  <a style={{ color: '#e2e2e2', textDecoration: 'none' }} href=''> </a> |<a style={{ color: '#e2e2e2', textDecoration: 'none' }} href={`tel:${branch.phone_1} `}> {branch.phone_1} </a>
                </p>
              </div>
               ))}
               </div>
          </div>
          <div className="footer-part-two">
            {
              allSubCategory.map((item, index) => (
                <div className='footer-box' key={index}>
                  <h3 onClick={() => handleCategories(item)}>{item.name}</h3>
                  <ul>
                    {item.children.map((child, childIndex) => (
                      <ul key={childIndex}>
                        <li onClick={() => handleSubCategory(child, item)}>
                          <a href={child.link}>{child.name}</a>

                        </li>
                      </ul>
                    ))}
                  </ul>
                </div>
              ))
            }
            <div className='footer-box'>
              <h3>Brands</h3>
              <ul>
                {
                  brands.map((brand, index) => (
                    <ul key={index}>
                      <li onClick={() => navigate(`/products?brands=${brand.id}`)}>
                        {brand.name}
                      </li>
                    </ul>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="footer-part-three">
            <p>© 2023. All Rights Reserved.</p>
            <p>Designed by : Chetan Kumar Neupane</p>
            <p>Powered by Tecfuge</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabFooter
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { getSearchApi } from "../../Products/apiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import '../../styles/home.scss';
import '../../styles/mobile-home.scss';
import './hero.scss'

function Hero() {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchBoxRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [title,setTitle] = useState('');
  const [description, setDiscription] = useState('');
  // const [currentContent, setCurrentContent] = useState(null);
  
  const sliderRef = useRef(null);
 
  const content =[
          {
            "id": 213,
            "thumbnail_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/hanging-lamp.jpg",
            "banner_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/hanging-lamp.jpg",
            "title": "Tecnolight",
            "description": "Indoor and Outdoor Lighting",
            "button_link": "https:\/\/web.sunrisegroupme.com\/products",
            "button_text": "Read more"
        },{
            "id": 176,
            "thumbnail_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/pexels-pixabay-356048.png",
            "banner_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/pexels-pixabay-356048.png",
            "title": "SUNRISE",
            "description": "Indoor and Outdoor Lighting",
            "button_link": "https:\/\/web.sunrisegroupme.com\/products",
            "button_text": "Read more"
        },
        {
          "id": 176,
          "thumbnail_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/pexels-pixabay-356048.png",
          "banner_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/pexels-pixabay-356048.png",
          "title": "SUNRISE",
          "description": "Indoor and Outdoor Lighting",
          "button_link": "https:\/\/web.sunrisegroupme.com\/products",
          "button_text": "Read more"
      },
      {
          "id": 213,
          "thumbnail_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/hanging-lamp.jpg",
          "banner_img": "https:\/\/app.sunrisegroupme.com\/storage\/app\/public\/banner_images\/hanging-lamp.jpg",
          "title": "Tecnolight",
          "description": "Indoor and Outdoor Lighting",
          "button_link": "https:\/\/web.sunrisegroupme.com\/products",
          "button_text": "Read more"
      }
    ]

  useEffect(() => {
    setImageUrl(content[0].thumbnail_img);
    setTitle(content[0].title);
    setDiscription(content[0].description);
  }, []); 

  var settings = {
    dots: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 3000,
    loop:true,
    navigator:false,
    afterChange: (index) => {
      setImageUrl(content[index].thumbnail_img);
      setTitle(content[index].title);
      setDiscription(content[index].description);
      const bgImage = document.querySelector(".background-image");
      bgImage.classList.add("animate");
      const textdata = document.querySelector(".overlay");
    textdata.classList.add("overlay-animate");
    setTimeout(() => {
      bgImage.classList.remove("animate");
      textdata.classList.remove("overlay-animate");
    }, 1000); // Adjust the delay to match the animation duration
    },
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:0,
          slidesToScroll: 0
        }
      }
    ]
  };


  const handleSearch = (value) => {
    setSearchTerm(value);
    getSearchApi({ q: value }, (results) => {
      setSearchResults(results);
      // Set the active index to the first item in the search results
      setActiveIndex(results.length > 0 ? 0 : 0);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchResults.length > 0) {
      // If Enter is pressed and there are search results, navigate to the first result
      navigate(`/product/${searchResults[0].id}`);

    }
  };


  return (
    <div className="main">
      <div className="background-image-container">
      <div className="search-container">
          <div className="search-bar" ref={searchBoxRef}>
            <input
              type="text"
              placeholder="Search a product..."
              onChange={(e) => handleSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {/* <i className="fas fa-search">Search</i> */}
            <span className="search_hero">
              <FontAwesomeIcon
                icon={faSearch}
                style={{ padding: "0 0.5rem" }}
              />
              Search
            </span>
          </div>
          {searchResults.length ? (
            <div className="results-list">
              {searchResults.length > 0
                ? searchResults.map((item, index) => (
                    <div
                      className="result"
                      onClick={() => navigate(`/product/${item.id}`)}
                    >
                      <div className="result-img">
                        {item && item.image && item.image?.length ? (
                          <img src={item?.image[0]} alt="result-img" />
                        ) : null}
                      </div>
                      <div className="result-content">
                        <h4>{item.name}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
        </div>
        <div
          className="background-image"
          style={{
            backgroundImage:` url(${imageUrl})`
          }}
        />
        <div className="overlay">
            <h2>{title || "heading"}</h2>
            <p>{description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur mollitia dolorum"}</p>
        </div>
        <div className="thubnail-main">
          <Slider ref={sliderRef} {...settings}>
            {content.map((item, index) => (
              <div key={index} className="carrd">
                <div className="img-main">
                  <div className="img">
                    <div className="thumbnails" style={{position:'relative'}}>
                      <img className="caroimage" src={item.thumbnail_img} alt="" onClick={()=>sliderRef.current.slickGoTo(index)} />
                      <h6 style={{margin:'0', position:'absolute',bottom :'1rem',left:'1rem',right:'1rem',color:'white'}}>
                        {content[index].title}
                        <img className="thumbarrow" style={{border:'none'}} src="/static/media/Line 42.3489c727b2a5fea5f30b2126510c283f.svg" alt="img"></img>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Hero;
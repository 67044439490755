import axios from "axios";
import { getGlobalCookie } from ".";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
var axiosInstance = axios.create({});
// axiosInstance.defaults.baseURL = "http://127.0.0.1:8000/";      
axiosInstance.defaults.baseURL = "https://laravel.firstlogicmetalab.com/";         

// axiosInstance.defaults.baseURL = "https://app.sunrisegroupme.com/";

  
axiosInstance.interceptors.request.use(
  function (config) {
    const token = getGlobalCookie("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

export default axiosInstance;

import React, { useEffect, useState } from 'react';
import '../styles/mobileheader.scss';
import { backArrow, fullLogo } from '../assets/mobile';
import MobileHeroHome from './MobileHeroHome';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import MobileHeaderAbout from './MobileHeaderAbout';
import MObileIcon from '../assets/mobile/th-large.svg'
import { getSearchApi } from '../Products/apiServices';

const MobileHeader = () => {
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(false);

  const toggleButton = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    if (openNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openNav]);

  const location = useLocation();
  const isProductPage = location.pathname.includes('/product')
  const isCartPage = location.pathname.includes('/cart')


  const handleSearch = (value) => {
    getSearchApi({ q: value }, (results) => {
      setSearchResults(results);
      // Set the active index to the first item in the search results
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchResults.length > 0) {
      // If Enter is pressed and there are search results, navigate to the first result
      navigate(`/product/${searchResults[0].id}`);
    }
  };

  return (
    <header className='container-fluid mobile-header-section'>
      {openNav && (
        <div className='nav_fixed'>
          <div className='overlay1'>
            <div onClick={toggleButton} className='close_icon'>
              <i className="fa fa-close"></i>
            </div>
            <div className='modal-contentD  '>
              <ul>
                <Link className='nav-content' onClick={toggleButton} to="/">
                  <li className='nav-head'>Home</li>
                </Link>
                <Link className='nav-content' onClick={toggleButton} to="/about-us?messages">
                  <li className='nav-head'>About Us</li>
                </Link>
                <Link className='nav-content' onClick={toggleButton} to="/products">
                  <li className='nav-head'>Shop</li>
                </Link>
                <Link className='nav-content' onClick={toggleButton} to="/cart">
                  <li className='nav-head'>ProductCart</li>
                </Link>
                <Link className='nav-content' onClick={toggleButton} to="/blog">
                  <li className='nav-head'>Blog</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className='container mobile-top-header'>
        {
          isProductPage ? null :
            <div className='logo-section'>
              {
                isCartPage ? null :
                  <button className='btn ' onClick={toggleButton}>
                    <img src={MObileIcon} alt='logo' />
                  </button>
              }
              <Link to="/"><img src={fullLogo} alt="logo" className='logo' /></Link>
            </div>
        }

        {location.pathname.includes('product') && (
          <>
            <div className='back-button' onClick={() => navigate(-1)}>
              <img src={backArrow} alt="back" />
              <span>Back</span>
            </div>
          </>
        )}

        {location.pathname.includes('products') && (
          <>
            <div className='search-field'>
              <input type="text" placeholder='Search a Product...' onChange={(e) => handleSearch(e.target.value)} onKeyPress={handleKeyPress} />
              <i className="fas fa-search"></i>
            </div>
            {searchResults.length ?
              <div className='results-list'>
                {
                  searchResults.length > 0 ?
                    searchResults.map((item, index) => (
                      <div className='result' onClick={() => navigate(`/product/${item.id}`)}>
                        <div className='result-img'>
                          <img src={item.image[0]} alt='result-img' />
                        </div>
                        <div className='result-content'>
                          <h4>{item.name}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    )) : null
                }
              </div> : null
            }
          </>
        )}

        {location.pathname.includes('cart') && (
          <div className='breadcrumbs'>
            <Link to="/" style={{ textDecoration: 'none', margin: '0px', padding: '0' }}>
              <span>Home</span>
            </Link>
            <Link style={{ textDecoration: 'none', margin: '0px', padding: '0' }}><span>&gt;</span></Link>
            <Link to="/products" style={{ textDecoration: 'none', margin: '0px', padding: '0' }}>
              <span>Product Listing</span>
            </Link>
          </div>
        )}

        {location.pathname.includes('profile') && (
          <div className='breadcrumbs'>
            <Link to="/" style={{ textDecoration: 'none', margin: '0px', padding: '0' }}>
              <span>Home</span>
            </Link>
            <Link style={{ textDecoration: 'none', margin: '0px', padding: '0' }}><span>&gt;</span></Link>
            <Link to="/profile" style={{ textDecoration: 'none', margin: '0px', padding: '0' }}>
              <span>Profile</span>
            </Link>
          </div>
        )}

        {!location.pathname.includes('products') &&
          !location.pathname.includes('product') &&
          !location.pathname.includes('cart') &&
          !location.pathname.includes('profile') && (
            <div className='smat-touch-section'>
              <button className='btn btn-light'>Smart Touch</button>
            </div>
          )}
      </div>

      <div>
        {location.pathname === '/' ? <MobileHeroHome /> : location.pathname === '/about-us' ? <MobileHeaderAbout /> : null}
      </div>
    </header>
  );
}

export default MobileHeader;

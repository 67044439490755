import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/common.scss";
import "./styles/header.scss";
import "./styles/footer.scss";
import "./styles/container.scss";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import { Footer, MobileNavigator } from "./components";
import { useMediaQuery } from "react-responsive";
import TabFooter from "./components/TabFooter";
import { useDispatch, useSelector } from "react-redux";
// import { footerAboutApi, footerContentApi, } from "./components/apiServices";
import { footerAboutApi, footerContentApi, } from "./components/apiServices";
import { getBrandsApi } from "./home/apiServices.js";
import { getCartListApi, getCategoriesApi } from "./Products/apiServices.js";
import { getGlobalCookie } from "./utils/index.js";
import { getProfileApi } from "./profile/apiServices.js";
import Popup from "./components/Popup.jsx";
import { setShowPopUp } from "./slices/sharedSlice.js";
import { getBlogApi } from "./home/apiServices.js";
import { getHomeApi } from './home/apiServices.js';

// Lazy Load the pages
const Home = lazy(() => import('./home/containers/Home'));
const AboutUs = lazy(() => import('./AboutUs/containers'));
const Products = lazy(() => import('./Products/productList/containers'));
const ProductView = lazy(() => import('./Products/ProductView/containers'));
const ProductCart = lazy(() => import('./Products/ProductCart/containers'));
const Profile = lazy(() => import('./profile/containers/Profile'));
const Blogs = lazy(() => import('./Blogs/components/Blogs'));
const BlogDetail = lazy(() => import('./Blogs/components/BlogDetail'));
// const Csr = lazy(() => import('./Blogs/components/Csr'));
const CsrDetail = lazy(() => import('../src/AboutUs/components/CsrDetails.jsx'));
const ProjectDetail = lazy(() => import('../src/AboutUs/components/ProjectDetails.jsx'));
const PrivacyPolicy = lazy(() => import('./Blogs/components/PrivacyPolicy.jsx'));
const TermsAndCondition = lazy(() => import('./Blogs/components/TermsAndCondition.jsx'));

function AppContent() {
  const location = useLocation();
  const dispatch = useDispatch()

  const { blog, home } = useSelector(state => state.homeState);

  useEffect(() => {
    if (!home.length) {
      dispatch(getHomeApi());
    }
    if (!blog.length) {
      dispatch(getBlogApi());
    }
  }, [dispatch, blog.length, home.length]);
  

  const token = getGlobalCookie('token');
  const { popupMessage, showPopup, thankYou } = useSelector(state => state.sharedState)

  useEffect(() => {
    dispatch(getProfileApi())
  }, [dispatch])
  useEffect(() => {
    dispatch(footerContentApi());
    dispatch(getBrandsApi());

    dispatch(footerAboutApi());
    dispatch(getCategoriesApi())
    if (token) {
      dispatch(getCartListApi())
    }
  }, [])

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTab = useMediaQuery({ query: "(min-width: 768px) and (max-width: 1223px)" });

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} exact={true} />
          <Route path="/about-us" element={<AboutUs />} exact={true} />
          <Route path="/products" element={<Products />} exact={true} />
          <Route path="/product/:id" element={<ProductView />} exact={true} />
          <Route path="/cart" element={<ProductCart />} exact={true} />
          <Route path="/profile" element={<Profile />} exact={true} />
          <Route path="/blog" element={<Blogs />} exact={true} />
          <Route path="/blog/:id" element={<BlogDetail />} exact={true} />
          <Route path="/about-us/csrdetails/:id" element={<CsrDetail />} exact={true} />
          <Route path="/about-us/projectdetails/:id" element={<ProjectDetail />} exact={true} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} exact={true} />
          <Route path="/terms&condition" element={<TermsAndCondition />} exact={true} />
          <Route path="*" element={<div><h1>404</h1></div>} exact={true} />

        </Routes>
        {
          showPopup ?
            <Popup message={popupMessage} thankYou={thankYou} className="my-custom-class" onClose={() => { dispatch(setShowPopUp(false)); window.location.reload(false) }} /> : null
        }
        {isDesktopOrLaptop && <Footer />}
        {isMobile && <MobileNavigator location={location} />}
        {isTab && <TabFooter />}
      </Suspense >
    </>
  )
}

function App() {


  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;

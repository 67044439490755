import React from 'react'
import { blogsPreview, } from '../../assets';
import { line66 } from '../../assets/mobile';
import { useSelector } from 'react-redux';

const MobileBlogs = ({ content }) => {

  const { blog } = useSelector(state => state.homeState)
  const lastTwoBlogs = blog.slice(-2);


  return (
    <section className='mobile-blogs-section container-fluid'>
      <div className='mobile-blogs container'>
        <div className='top-section'>
          <h1>{content?.title ? content.title : ''}</h1>
        </div>
        <div className='bottom-section'>
          <div className='blogs'>
            {
              lastTwoBlogs.map((blog, index) =>
                <div className='blog' key={index}>
                  <div className='blog-img'>
                  <img src={blog.image} alt="" />
                  </div>
                  <h4>{blog.title}</h4>
                  <p>{blog.description && blog.description.length < 100 ? blog.description : `${blog.description.substring(0, 100)}...`}</p>
                  <div className='read-more'>
                    <p>Read Full Articles</p>
                    <img src={line66} alt="" />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileBlogs
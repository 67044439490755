import React from "react";
import { testimonialRectangle1 } from "../../assets";
import TabTestimonial from "./TabTestimonial";
import { useMediaQuery } from "react-responsive";
import { Fade, Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/testimonial.css';

const Testimonials = ({ testimonials, content }) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style, /* add your custom styles here */ }}
        onClick={onClick}
      />
    );
  };
  
  // Custom previous arrow component
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style, /* add your custom styles here */ }}
        onClick={onClick}
      />
    );
  };
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          }
        },
      ],
      nextArrow: <NextArrow />, // Custom next arrow component
      prevArrow: <PrevArrow />, // Custom previous arrow component
    };
  
  
  
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
 
  return (
    <>
      <Fade bottom>
        <section className="testimonials-section container-fluid">
          <div className="container testimonials">
            <div className="top-section">
              <h1>{content?.title ? content.title : ""}</h1>
              <div className="top-section-content">
                <p>{content?.description ? content.description : ""}</p>
              </div>
            </div>
            {isTablet ? (
              <TabTestimonial />
            ) : (
              <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                  <div
                    style={{
                      alignItems: index === 1 ? "flex-start" : "flex-end",
                    }}
                  >
                    <div className="rectangular-container-item" key={index}>
                      <div className="testimonial-main">
                        <img
                          
                          src={testimonialRectangle1}
                          alt=""
                          height="330px"
                        />
                        <p className="testimonial-content">
                          {testimonial.testimonial_content}
                        </p>
                      </div>
                    </div>
                    <div className="testimonial-user-container">
                      <div
                        className="user"
                        key={index}
                        style={{
                          flexDirection:
                          //   (index + 1) % 2 === 0 ? "row-reverse" : "row",
                          // marginLeft: index === 1 ? "-2.5%" : "",
                          // paddingRight: index === 0 ? "21px" : "",
                          (index + 1) % 2 === 0 ? "row" : "row",
                          marginLeft: "-2.5%",
                          paddingRight: "21px",
                          justifyContent: 'right',
                          display: 'grid',
                        }}
                      >
                        <div className="user-content">
                          <h3 
                            style={{
                              textAlign: 'right'
                            }}
                          >{testimonial.author}</h3>
                          <p
                            style={{
                              // textAlign:
                              //   index === 0 && testimonial.job_role
                              //     ? "right"
                              //     : "left",
                              textAlign: 'right'
                            }}
                          >
                            {testimonial.job_role}
                          </p>
                        </div>
                        <img src={testimonial.author_img} alt="" />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </section>
      </Fade>
    </>
  );
};

export default Testimonials;
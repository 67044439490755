import React from 'react'
import QuotationModal from './QuotationModal'

const FooterQuotation = () => {
  return (
    <div className="quotation-box">
      <div className="quotation-box-title">
        <h1>
          Let’s talk about your project.
        </h1>
      </div>
      <div className="quotation-box-cta">
        <QuotationModal />
      </div>
    </div>
  )
}

export default FooterQuotation
import React from 'react'
import QuotationModal from '../../components/QuotationModal'
import { Fade } from 'react-reveal'


const Quotation = () => {

  return (
    <Fade bottom>
      <section className='quotation-section container-fluid '>
        <div class="container quotation">
          <div class="quotation-box">
            <div class="quotation-box-title">
              <h1>
                Let’s talk about your project.
              </h1>
            </div>
            <div class="quotation-box-cta">
              <QuotationModal mode="general" />
            </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default Quotation
import { globalExportService, globalGetService, globalPostService, } from "../utils/globalApiServices";
import { applicationSuccess, brandsSuccess, categoriesSuccess, loadingOff, loadingOn } from "../slices/sharedSlice";
import { cartSuccess, featuredProductsSucces,newArrivalsSucces,finishSuccess, materialsSuccess, powersSuccess, productSucces, productsSuccess, subCategoriesSuccess } from "../slices/productsSlice";
import { checkApiStatus } from "../utils";

export const getProductsApi = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/products", params)
        .then((response) => {
          console.log("dsddasdsdadsada");
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(productsSuccess(data))
    } catch (error) {

    }
  }
};

export const getProductApi = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService(`api/product/${id}`)
        .then((response) => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(productSucces(data))
    } catch (error) {

    }
  }
}
export const getFeaturedProductsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService('/api/featured')
        .then(response => {
          dispatch(loadingOff())
          console.log(response.data.Data,'featured');

          return response.data.Data
        })
      dispatch(featuredProductsSucces(data))
    } catch (error) {

    }
  }
}

export const getNewArrivalsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService('/api/new-arrivals') //need to change to new arrivals
        .then(response => {
          dispatch(loadingOff())
          console.log(response.data.Data,'new-arrivals');
          return response.data.Data

        })
        console.log(data, 'infooo');
      dispatch(newArrivalsSucces(data))
    } catch (error) {

    }
  }
}



export const addToCartApi = (payload, reload = true,enqueueSnackbar ) => {
  return (dispatch) => {
    // dispatch(loadingOn())
    globalPostService("api/add-to-cart", payload)
      .then((response) => {;
        if(checkApiStatus(response)){
          if (reload) {
            dispatch(getCartListApi())
          }
          enqueueSnackbar('Product Added to Cart Successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        }else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        }
        dispatch(loadingOff())
      })
      .catch((error) => {
      });
  }
}

export const ReduceFromCartApi = (payload) => {
  return (dispatch) => {
    dispatch(loadingOn())
    globalPostService("api/reduce-from-cart", payload)
      .then((response) => {
        dispatch(loadingOff())
      })
      .catch((error) => {
      });
  }


}


export const getCartListApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/display-cart")
        .then(response => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(cartSuccess(data))
    } catch (error) {

    }
  }
}

export const getApplicationAreasApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/product-application-areas")
        .then(response => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(applicationSuccess(data))
    } catch (error) {

    }
  }
}
export const getMaterialsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/materials")
        .then(response => {
          dispatch(loadingOff())
          return response.data.Data
        })
      dispatch(materialsSuccess(data))
    } catch (error) {

    }
  }
}
export const getBrandsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/brands")
        .then(response => {
          dispatch(loadingOff())
          return response.data.Data
        })
      dispatch(brandsSuccess(data))
    } catch (error) {

    }
  }
}
export const getCategoriesApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/categories")
        .then(response => {
          dispatch(loadingOff())
          return response.data.Data
        })
      dispatch(categoriesSuccess(data))
    } catch (error) {

    }
  }
}
export const getSubCategoriesApi = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService(`api/category/${params.category}`)
        .then((response) => {
          dispatch(loadingOff())
          return response.data.Data[0].children
        })
      dispatch(subCategoriesSuccess(data))
    } catch (error) {

    }
  }
}
export const getPowerApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/power-sections")
        .then(response => {
          dispatch(loadingOff())
          return response.data
        })
      dispatch(powersSuccess(data))
    } catch (error) {

    }
  }
}
export const getFinishApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService("api/finish")
        .then(response => {
          dispatch(loadingOff())
          return response.data
        })
      dispatch(finishSuccess(data))
    } catch (error) {

    }
  }
}



export const getHomeApi = (setData) => {
  globalGetService('api/home-page-content')
    .then((response) => {
      setData(response.data.data)
      console.log("dsddasdsdadsada");

    })
    .catch((error) => {
    })
}

export const getSearchApi = (params, setData) => {
  globalGetService('api/search', params)
    .then((response) => {
      setData(response.data.results)
    })
    .catch((error) => {

    })
}

export const orderRequestApi = (data) => {
  return new Promise((resolve, reject) => {
    globalPostService('api/orders', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeProductApi = (id) => {
  return new Promise((resolve, reject) => {
    globalPostService(`api/remove-from-cart?id=${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCartPdf = () => {
  globalGetService('api/cart-pdf')
    .then((response) => {
    })
}

export const clearCartApi = () => {
  return new Promise((resolve, reject) => {
    globalPostService('api/clear-cart')
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const downloadCartPdfApi = () => {
  return new Promise((resolve, reject) => {
    globalExportService('api/cart-pdf')
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
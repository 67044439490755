import React, { useState, useEffect } from "react";
import { line42 } from "../assets";
import Badge from "react-bootstrap/Badge";
import { fadeIn } from "../utils/moition";
import { Pagination } from 'swiper/modules';
import { addToCartApi } from "../Products/apiServices";
import { Fade } from "react-reveal";
import { checkUserLogin } from "../utils";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from "react-redux";
import { authModalToggle, cartLoginToggle } from "../slices/sharedSlice";
import "../styles/featuredProducts.scss";
import 'swiper/swiper-bundle.css';
import { useMediaQuery } from "react-responsive";
import { Link } from 'react-router-dom';
import QuotationModal from "./QuotationModal";

const FeaturedProducts = ({ content, index }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { categories } = useSelector(state => state.sharedState)
  const [filteredProducts, setFilteredProducts] = React.useState([])
  const { featuredProducts } = useSelector(state => state.productState)
  useEffect(() => {
    setFilteredProducts(featuredProducts)
  }, [featuredProducts])
  const handleFilter = (id) => {
    setFilteredProducts(featuredProducts.filter((product) => product.category_id === id))
  }
  const handleAddtoCart = (id) => {
    if (checkUserLogin()) {
      dispatch(addToCartApi({ id: id, quantity: 1 }, true, enqueueSnackbar))
    } else {
      enqueueSnackbar('Please login to add to cart', { variant: 'warning', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'center', } })
      dispatch(authModalToggle())
      dispatch(cartLoginToggle())
    }
  }


  const isTablet = useMediaQuery({ query: "(max-width: 1400px)" })


  return (
    <Fade bottom duration="1000" distance="50px">
      <section className="featured-products-section container-fluid " style={{ background: '#fff' }}>
        <div className="featured-products container ">
          <div>
            <div className="featured-products-top-section row">
              <div className="featured-products-contents col-md-6">
                <div className="title ">
                  <div className="col-md-7 col-lg-6 col-xl-6 heading ">
                    <h1 className="featured-title">
                      {content?.title ? content.title : ""}
                    </h1>
                  </div>
                  <div className="col-md- view-more">
                    <p><Link to="/products" className="link-no-underline">View More Featured Product</Link></p>
                    <img src={line42} alt="img" />
                  </div>
                </div>
                <div className="content">
                  <p>{content?.description ? content.description : ""}</p>
                </div>
              </div>
              <div className="col-md-6 featured-products-categories">
                {
                  categories && categories.map((category, index) => (
                    <div className="box" key={index} onClick={() => handleFilter(category.id)}>
                      <span className="text">{category.name}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div

            className=""
          >
            <Swiper
              className="featured-products-bottom-section container"
              spaceBetween={24}
              modules={[Pagination]}
              autoplay={true}
              shortSwipes={true}
              slidesPerView={6}

              breakpoints={{

                576: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },

                768: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },

                992: {
                  slidesPerView: 4,
                  spaceBetween: 17,
                },

                1500: {
                  slidesPerView: 5,
                  spaceBetween: 24,
                },
                1800: {
                  slidesPerView: 6,
                  spaceBetween: 24,
                },
                2200: {
                  slidesPerView: 7,
                  spaceBetween: 24,
                },
              }}
            >
             {filteredProducts &&
                filteredProducts
                  .filter((feature) => feature.stock > 0) // Filter out products with stock value 0
                  .map((feature, index) => (
                    <SwiperSlide key={index}
                      variants={fadeIn("right", "spring", index * 0.5, 0.7)}
                      whileInView="show"
                      initial="hidden"
                      style={{paddingTop:"30px",paddingBottom:"30px",}}
                      >
                      {/* Other content for the component */}


                      <div className={`product-card ${index === feature.length - 1 ? "extra-lg extra-md" : ""} ${checkUserLogin() ? "logged-in" : ""}`}>
                        {feature.new_pro === "yes" && <img src={require('../assets/badge.png')} alt="" className="product-badge" />}
                        <div className="product-items">
                          <div className="product-image" onClick={() => navigate(`/product/${feature.id}`)}  >
                            <img src={feature.image} alt="product" />
                          </div>
                          <caption onClick={() => navigate(`/product/${feature.id}`)}>{feature.name}</caption>


                          <h4 onClick={() => navigate(`/product/${feature.id}`)}>AED<span> {feature.price} / Piece</span></h4>
                          <h4 onClick={() => navigate(`/product/${feature.id}`)}>Stock :<span> {feature.stock} </span></h4>


                          {
                            checkUserLogin() ?
                              <div>
                                <div>
                                  <p className="feature_dis">
                                    {feature.description && feature.description.length > 50 ? `${feature.description.substring(0, 50)}...` : feature.description}
                                  </p></div>
                                <div className="product-cta">
                                  {/* <caption>Stocks <span>{feature.stock}</span></caption> */}

                                  <button className="btn btn-add-to-cart" onClick={() => handleAddtoCart(feature.id)}  >Add to Cart</button>
                                  <QuotationModal />
                                </div>



                              </div> : <div><p className="cart_dis">Please login/Register to See the Stock Details. </p></div>}
                        </div>
                      </div>


                    </SwiperSlide>

                  ))}
            </Swiper>
            <div className="">
              {filteredProducts && filteredProducts.length > 0 ? (
                <Swiper
                  className="featured-products-bottom-section container"
                  spaceBetween={24}
                  modules={[Pagination]}
                  autoplay={true}
                  shortSwipes={true}
                  slidesPerView={6}
                // ...other Swiper configurations
                >
                  {filteredProducts.map((feature, index) => (
                    <SwiperSlide key={index}>
                      {/* Content for the product card */}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p>No products found</p>
              )}
            </div>

          </div>
        </div>
      </section>
    </Fade>
  );
};

export default FeaturedProducts;
import React, { useMemo } from 'react'
import '../styles/mobile-navigator.scss'
import { shop, shopDark } from '../assets/mobile'
import { Link } from 'react-router-dom'
import { checkUserLogin } from '../utils'
import LoginRegister from '../authorisation/container/LoginRegister'

const MobileNavigator = ({ location }) => {
  return useMemo(() => {
    return (
      <div className='mobile-navigator' style={{
        zIndex: '1000'
      }}>
        <div className='mobile-navigator-container'>
          <Link className='mobile-navigator-item ' to="/">
            {
              location.pathname === '/' ?
                <div className='active'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <circle cx="32" cy="32" r="32" fill="#F1F1F1" />
                  </svg>
                  <i className='fa fa-home'></i>
                </div> :
                <i className='fa fa-home'></i>
            }

          </Link>
          <Link className='mobile-navigator-item ' to="/products">
            {
              location.pathname === '/products' ?
                <div className='active'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <circle cx="32" cy="32" r="32" fill="#F1F1F1" />
                  </svg>
                  <img src={shopDark} alt='shop' />
                </div> :
                <img src={shop} alt='shop' />
            }
          </Link>
          <div>
            {
              checkUserLogin() ?
                <Link className='mobile-navigator-item ' to="/cart">
                  {
                    location.pathname === '/cart' ?
                      <div className='active'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                          <circle cx="32" cy="32" r="32" fill="#F1F1F1" />
                        </svg>
                        <i className='fa fa-shopping-cart'></i>
                      </div> :
                      <i className='fa fa-shopping-cart'></i>
                  }
                </Link> :
                <LoginRegister mobileNavigatorCart={true} />


            }
          </div>
          {
            checkUserLogin() ?
              <Link className='mobile-navigator-item' to="/profile">
                {
                  location.pathname === '/profile' ?
                    <div className='active'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <circle cx="32" cy="32" r="32" fill="#F1F1F1" />
                      </svg>
                      <i className='fa fa-user'></i>
                    </div> :
                    <i className='fa fa-user'></i>
                }
              </Link> :
              <LoginRegister mobileNavigatorProfile={true} />

          }

        </div>
      </div>
    )
  }, [location])
}

export default MobileNavigator
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    featuredProducts: [],
    newArraivalsProducts: [],
    products:{},
    materials:[],
    subCategories:[],
    powers:[],
    finish:[],
    product:{},
    carts:[],
    selectedCategoriesName:{},
    filter: {}

}

const productSlice = createSlice ({
    name:'product',
    initialState,
    reducers:{
        featuredProductsSucces(state,action){
            return{
                ...state,
                featuredProducts:action.payload
            }
        },
        newArrivalsSucces(state,action){
            return{
                ...state,
                newArraivalsProducts:action.payload
            }
        },
        productsSuccess(state,action){
            return {
                ...state,
                products:action.payload
            }
        },
        materialsSuccess(state,action){
            return {
                ...state,
                materials:action.payload
            }
        },
        subCategoriesSuccess(state,action){
            return {
                ...state,
                subCategories:action.payload
            }
        },
        powersSuccess(state,action){
            return {
                ...state,
                powers:action.payload
            }
        },
        finishSuccess(state,action){
            return {
                ...state,
                finish:action.payload
            }
        },
        productSucces(state,action){
            return {
                ...state,
                product:action.payload
            }
        },
        cartSuccess(state,action){
            return {
                ...state,
                carts:action.payload
            }
        },
        selectedCategoryNameSuccess(state,action){
            return {
                ...state,
                selectedCategoriesName:action.payload
            }
        },
        setFilter(state,action){
            return {
                ...state,
                filter:action.payload
            }
        
        }
    }
})

const {actions,reducer} = productSlice;

export const {cartSuccess,featuredProductsSucces,newArrivalsSucces,powersSuccess, productsSuccess,materialsSuccess,subCategoriesSuccess,finishSuccess,productSucces,selectedCategoryNameSuccess, setFilter} = actions;

export default reducer;
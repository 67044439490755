import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFilter } from '../../slices/productsSlice';

const MobileProductCategory = ({ categories, content }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleCategories = (category) => {
    dispatch(setFilter({ category: category.id , categoryName : category.name }))
    navigate(`/products`)
  }
  return (
    <section className='mobile-application-areas-section container-fluid'>
      <div className='application-areas container'>
        <div className='top-section'>
          <h4>{content?.title ? content.title : ''}</h4>
        </div>
        <div className='bottom-section'>
          {
            categories.map((category, index) => (
              <div className='custom-box' onClick={() => handleCategories(category)} key={index}><p>{category.name}</p></div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default MobileProductCategory
import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { askQuotationApi } from './apiServices';
import { useDispatch } from 'react-redux';
import { setShowPopUp } from '../slices/sharedSlice';

/////////////// with validation
const QuotationModal = ({ mode }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ name: '', email: '', phone: '', message: '' });
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const onFieldChange = (field, value) => {
    if (field === 'email') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        setFormErrors({ ...formErrors, email: 'Invalid email address' });
      } else {
        setFormErrors({ ...formErrors, email: '' });
      }
    } else if (field === 'phone') {
      // Phone number validation regex (you can adjust this regex as needed)
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(value)) {
        setFormErrors({ ...formErrors, phone: 'Invalid phone number' });
      } else {
        setFormErrors({ ...formErrors, phone: '' });
      }
    }

    setData({ ...data, [field]: value });
  };

  const openPopup = (message) => {
    // dispatch(setPopupMessage(message))
    dispatch(setShowPopUp(true))

  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const validateForm = () => {
    const errors = {};

    if (!data.name) {
      errors.name = 'Name is required';
    }
    if (!data.email) {
      errors.email = 'Email is required';
    }
    if (!data.phone) {
      errors.phone = 'Phone is required';
    }
    if (!data.message) {
      errors.message = 'Message is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      // Form is valid, proceed with the API request
      askQuotationApi(data)
        .then((response) => {
          openPopup(response.message);
          setShow(false);
        });
    }
  };

  return (
    <>
      {mode === "general" ? (
        <button className="btn-quotation box" onClick={() => setShow(true)}>
          <span className='text'>Ask for Price Quotation</span>
        </button>
      ) : (
        <button className="btn btn-ask-for-quote" onClick={() => setShow(true)}>
          Ask for Quotation
        </button>
      )}
      <Modal show={show} onHide={() => setShow(false)} style={{ borderRadius: '10px', marginTop: '11%' }}>
        <Modal.Header closeButton style={{ backgroundColor: "#393d46", color: '#fff', borderBottom: 'none', }}>Ask for Quotation</Modal.Header>
        <Modal.Body style={{ backgroundColor: "#393d46" }}>
          <div style={{ display: 'flex', flexDirection: 'column', color: '#fff', gap: '15px' }}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" className="form-control" placeholder="Enter Name" id="name" autoComplete="off" value={data.name} onChange={(e) => onFieldChange('name', e.target.value)} />
              {formErrors.name && <div className="error-message">{formErrors.name}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="text" className="form-control" placeholder="Enter Email" id="email" autoComplete="off" value={data.email} onChange={(e) => onFieldChange('email', e.target.value)} />
              {formErrors.email && <div className="error-message">{formErrors.email}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input type="text" className="form-control" placeholder="Enter Phone No." id="phone" autoComplete="off" value={data.phone} onChange={(e) => onFieldChange('phone', e.target.value)} />
              {formErrors.phone && <div className="error-message">{formErrors.phone}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea type="text" className="form-control" placeholder="Enter Message" id="message" autoComplete="off" value={data.message} onChange={(e) => onFieldChange('message', e.target.value)} />
              {formErrors.message && <div className="error-message">{formErrors.message}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#393d46", borderTop: 'none' }}>
          <button className="btn-quotation box" style={{ backgroundColor: "#393d46", color: "#fff", border: "1px solid var(--Secondary-Color-2, #E2E2E2)", width: '100px', paddingTop: '2%', paddingBottom: '2%' }} onClick={onSubmit}>
            <span className='text' style={{ fontSize: '14px' }}>Submit</span>
          </button>
          <button className="btn-quotation box" style={{ backgroundColor: "#393d46", color: "#fff", border: "1px solid var(--Secondary-Color-2, #E2E2E2)", width: '100px', paddingTop: '2%', paddingBottom: '2%' }} onClick={() => setShow(false)}>
            <span className='text' style={{ fontSize: '14px' }}>Close</span>
          </button>
        </Modal.Footer>
      </Modal>

      {/* {isPopupVisible && <Popup message={popupMessage} onClose={closePopup} />} */}
    </>
  );
};

export default QuotationModal;


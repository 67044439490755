import React from 'react'

const MobileHeaderAbout = () => {
  return (
    <section className='mobile-hero-home-section container'>
      {/* <div className='contents container'>
        <div className='content'>
          <h1>Illuminate your space with style and efficiency</h1>
          <button>Learn More</button>
        </div>

      </div> */}
    </section>
  )
}

export default MobileHeaderAbout
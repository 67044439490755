import {
  globalGetService,
  globalPostService,

} from "../../utils/globalApiServices";
import {
  aboutUsMegaMenuSuccess,
  contactBasicSucess,
  footerSuccess,
  loadingOff,
  loadingOn,
  shopMegaMenuLeftSuccess,
  shopMenuRightSuccess,
  socailMediaLinksSucess,
  footerAboutSucess,
  sliderSucess,
  quotationSucess,
  contactShowroomSucess,
  contactBranchesSucess,
  contactHeadquartersSucess,
  seoSuccess
} from "../../slices/sharedSlice";

export const getApplicationAreasApi = (Data) => {
  globalGetService("api/product-application-areas")
    .then((response) => {
      Data(response.data.data);
    })
    .catch((error) => {
    });
};

//contactbasic
export const contactBasicApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/basic-details")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data.data;
          }
        );
      dispatch(contactBasicSucess(data));
    } catch (error) {
    }

  }
}

//footer about
export const footerAboutApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/footer-about")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data.data;
          }
        );
      dispatch(footerAboutSucess(data));
    }
    catch (error) {
    }
  }
}

//quation api
export const quotationApi = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const response = await globalGetService("api/orders");

      if (response && response.data) {
        const data = response.data;
        dispatch(quotationSucess(data));
      } else {
        console.error("Invalid response or missing data property.");
      }
      dispatch(loadingOff());
    } catch (error) {
      console.error("API call error:", error);
      dispatch(loadingOff());
    }
  };
};
//contact showroom
export const contactShowroomApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOff());
      const data = await globalGetService("api/showrooms")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data;
          }
        );
      dispatch(contactShowroomSucess(data));
    } catch (error) {
    }
  }
};

//contact header quater
export const contactHeadQuartersApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOff());
      const data = await globalGetService("api/headquarters")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data;
          }
        );
      dispatch(contactHeadquartersSucess(data));
    } catch (error) {
    }
  }
};

export const seoApi = (param) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/seo", param)
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data.data[0];
          }
        );
      dispatch(seoSuccess(data));
    } catch (error) {
    }
  }
};



//contact branches
export const contactBranchesApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOff());
      const data = await globalGetService("api/branches")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data;
          }
        );
      dispatch(contactBranchesSucess(data));
    } catch (error) {
    }
  }
};

//slider title
export const sliderAboutApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/banner-slides")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data.data;
          }
        );
      dispatch(sliderSucess(data));
    }
    catch (error) {
    }
  }
}



export const socailMediaLinksApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOff());
      const data = await globalGetService("api/social-links")
      
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data;
          }
        );
      dispatch(socailMediaLinksSucess(data));
    } catch (error) {
    }
  }
};


//footer
export const footerContentApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/all-subcategories")
        .then(
          (response) => {
            dispatch(loadingOff());
            return response.data.Data;
          }
        );
      dispatch(footerSuccess(data));
    } catch (error) {
    }
  };
};

export const askQuotationApi = (data) => {
  return new Promise((resolve, reject) => {
    globalPostService("api/quote", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShopMenuLeftApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/shop-menu-left").then(
        (response) => {
          dispatch(loadingOff());
          return response.data;
        }
      );
      dispatch(shopMegaMenuLeftSuccess(data));
    } catch (error) {
    }
  };
};
export const getShopMenuRightApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/shop-right-menu").then(
        (response) => {
          dispatch(loadingOff());
          return response.data;
        }
      );
      dispatch(shopMenuRightSuccess(data));
    } catch (error) {
    }
  };
};
///megamenu
export const getAboutUsMegaMenuApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/aboutus-megamenu").then(
        (response) => {
          dispatch(loadingOff());
          return response.data;
        }
      );
      dispatch(aboutUsMegaMenuSuccess(data));
    } catch (error) {
    }
  };
};
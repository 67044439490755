import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: [],
    missionVision: {},
    company: [],
    faq: [],
    clients: [],
    certificate: [],
    project: [],
    gallery: []

}

const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {
        messageSuccess(state, action) {
            return {
                ...state,
                messages: action.payload
            }
        },
        missionVissionSucces(state, action) {
            return {
                ...state,
                missionVision: action.payload
            }
        },
        companySuccess(state, action) {
            return {
                ...state,
                company: action.payload
            }
        },
        faqSuccess(state, action) {
            return {
                ...state,
                faq: action.payload
            }
        },
        clientsSuccess(state, action) {
            return {
                ...state,
                clients: action.payload
            }
        },
        certificateSuccess(state, action) {
            return {
                ...state,
                certificate: action.payload
            }
        },
        projectSuccess(state, action) {
            return {
                ...state,
                project: action.payload
            }
        },
        gallarySuccess(state, action) {
            return {
                ...state,
                gallery: action.payload
            }
        }
    }
})

const { actions, reducer } = aboutSlice;

export const { messageSuccess, missionVissionSucces, companySuccess, faqSuccess, clientsSuccess, certificateSuccess, projectSuccess, gallarySuccess } = actions;

export default reducer;
import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, EffectFade } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const MobileHeroHome = () => {
  const { heroSlides } = useSelector((state) => state.homeState);
  const navigate = useNavigate();

  return (
    <div className=''>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        modules={[Autoplay, EffectFade]}
        autoplay={{
          delay: 2000, // Set the time (in milliseconds) between slides
          disableOnInteraction: false, // Prevent autoplay from stopping on user interaction
        }}
        effect="fade"
      >
        {heroSlides.map((item, index) => (
          <SwiperSlide key={index} className='mobile-hero-section' style={{ background: `url(${item.banner_img})`, backgroundSize: 'cover', backgroundPosition: 'center',  width:'20%', display:'flex'  }}>
            <div >
              <div className='contents container'>
                <div className='content'>
                  <h1>Illuminate your space with style and efficiency</h1>
                  <button onClick={() => navigate('/about-us?projects-services')}>Learn More</button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MobileHeroHome;

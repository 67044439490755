import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const MobileFeatuedProducts = ({ content }) => {
  const [filteredProducts, setFilteredProducts] = React.useState([])

  const { featuredProducts } = useSelector(state => state.productState)
  const { categories } = useSelector(state => state.sharedState)
  const navigate = useNavigate()
  useEffect(() => {
    setFilteredProducts(featuredProducts)
  }, [featuredProducts])
  const handleFilter = (id) => {
    if (id === "all") {
      setFilteredProducts(featuredProducts)
    } else {
      setFilteredProducts(featuredProducts.filter((product) => product.category_id === id))
    }

  }
 

  return (
    <section className='mobile-featured-products-section container-fluid'>
      <div className='featured-products container'>
        <div className='top-section'>
          <h4>{content?.title ? content.title : ''}</h4>
        </div>
        <div className='bottom-section'>
          <div className='categories'>
            <Swiper
              slidesPerView={3.5}
              spaceBetween={10}
              className='tabs'
              style={{ zIndex: '10' }}>
              {
                categories && categories.map((item, index) => (
                  <SwiperSlide className='tab' onClick={() => handleFilter(item.id)}><p>{item.name}</p></SwiperSlide>

                ))
              }
              <SwiperSlide className='view-all' onClick={() => handleFilter("all")}>
                View All
              </SwiperSlide>
            </Swiper>

          </div>
          <div className='mobile-featured-products-cards'>
            <Swiper
              slidesPerView={filteredProducts.length >= 3 ? 3 : filteredProducts.length <= 2 ? 1 : 1}
              spaceBetween={10}
            >
              {
                filteredProducts && filteredProducts.map((item, index) => (
                  <SwiperSlide className='card'>
                    <div className='card-wrapper'onClick={() => navigate(`/product/${item.id}`)}>
                      <img src={item.file_paths} alt='product' />
                    </div>
                    <div className='card-content' onClick={() => navigate(`/product/${item.id}`)}>
                      <p>{item.name}</p>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileFeatuedProducts